<template lang="pug">
div
  v-row
    v-col(cols="12")
      v-text-field(
        label="Code"
        :value="code"
        @input="codeToUppercase($event)"
        :error-messages="costCenterPostErrors.code"
      )
    v-col(cols="12")
      v-text-field(
        label="Description"
        v-model="description"
        :error-messages="costCenterPostErrors.description"
      )
    v-col(:cols="editMode ? 6 : 12")
      v-btn(
        :color="editMode ? 'yellow darken-3' : 'primary'"
        small
        block
        dark
        :loading="costCenterPosting"
        @click="sendRequest"
      )
        span save
    v-col(v-if="editMode" cols="6")
      v-btn(
        color="red"
        dark
        small
        block
        @click="cancel"
      )
        span cancel
</template>
<script>
import costCenterRepository from '@/repositories/cost-center.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [costCenterPostVars, costCenterPostVarNames] = requestVars({
  identifier: 'cost-center',
  request: 'post',
})
const costCenterPostHandler = new VueRequestHandler(null, costCenterPostVarNames)

const inputVars = () => ({
  id: null,
  code: null,
  description: null,
})

export default {
  name: 'CostCenterCreate',
  props: {
    defaultValue: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ...inputVars(),
      ...costCenterPostVars,
    }
  },
  computed: {
    editMode() {
      return !this.$objectEmpty(this.defaultValue)
    },
  },
  watch: {
    defaultValue(data) {
      this.populateInputs(data)
    },
  },
  methods: {
    sendRequest() {
      if (this.editMode) {
        this.updateCostCenter()
        return
      }
      this.storeCostCenter()
    },
    storeCostCenter() {
      const handler = costCenterPostHandler
      const repository = costCenterRepository.store
      const { data } = this.getFieldInputs()
      handler.setVue(this)
      handler.execute(repository, [data])
    },
    updateCostCenter() {
      const handler = costCenterPostHandler
      const repository = costCenterRepository.update
      const { id, data } = this.getFieldInputs()
      handler.setVue(this)
      handler.execute(repository, [id, data])
    },
    getFieldInputs() {
      const excluded = ['id']
      const data = Object.keys(inputVars())
        .filter(key => !excluded.includes(key))
        .reduce((accum, key) => {
          accum[key.camelToSnakeCase()] = this[key]
          return accum
        }, {})
      const { id } = this
      return { id, data }
    },
    codeToUppercase(e) {
      this.code = e.toUpperCase()
    },
    reset() {
      const defaults = inputVars()
      Object.keys(defaults).forEach(key => {
        this[key] = defaults[key]
      })
    },
    cancel() {
      this.reset()
      this.$emit('update:defaultValue', {})
    },
    populateInputs(data) {
      const editData = Window.objectChangeKeyCase(data)
      Object.keys(editData).forEach(key => {
        this[key] = editData[key]
      })
    },
  },
}
</script>
